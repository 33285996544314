import { useEffect, useState } from 'react';
import { DateLocalizer } from 'react-widgets/IntlLocalizer';
import MaskedTextInput from 'react-text-mask';
import { DatePicker, Localization } from 'react-widgets';

import { useRouter } from 'next/router';

import clsx from 'clsx';

import { useTranslate } from '@/hooks/use-translate';
import decodeDate from '@/utils/decodeDate';

import { en } from './en';

import './LabeledDatePicker.scss';

type LabeledDatePickerProps = {
	dateValue?: string;
	label?: string;
	name?: string;
	placeholder?: string;
	important?: boolean;
	error?: string;
	disabled?: boolean;
	onChange?: (date: string) => void;
	language?: 'ru' | 'en';
};

export default function LabeledDatePicker(props: LabeledDatePickerProps) {
	const { dateValue, label, placeholder, important, error, disabled, onChange } = props;

	const [value, setValue] = useState(null);
	const [inputValue, setInputValue] = useState(props.dateValue);
	const router = useRouter();
	const { locale } = router;

	const t = useTranslate(en);

	const { format } = Intl.DateTimeFormat('ru');

	useEffect(() => {
		let defaultValue = dateValue;
		if (defaultValue && defaultValue !== value) {
			if (defaultValue.includes('-')) {
				defaultValue = decodeDate(defaultValue);
			}
			const splittedDate = defaultValue.split('.');
			const date = new Date(+splittedDate[2], +splittedDate[1] - 1, +splittedDate[0]);
			setValue(date);
			setInputValue(defaultValue);
			onChange && onChange(format(date));
		}
		else {
			setValue(null);
			setInputValue(null);
		}
	}, [dateValue]);

	// Добавляет стили для сегодняшнего дня
	useEffect(() => {
		document.head.insertAdjacentHTML(
			'beforeend',
			`<style>
							.rw-cell[aria-label="${new Date().toLocaleDateString()}"][aria-selected="false"] {
								border: .2rem solid #4587E8;
							}
						</style>`,
		);
	}, []);

	const dateSendHandler = () => {
		if (inputValue?.length === 10 && !inputValue.includes('_')) {
			const splittedValue = inputValue.split('.');

			if (+splittedValue[0] > 31 || +splittedValue[1] > 12 || +splittedValue[2] > 2037) {
				setInputValue('');
				setValue(null);
			}
			else {
				const date = new Date(+splittedValue[2], +splittedValue[1] - 1, +splittedValue[0]);
				setValue(date);
				onChange && onChange(format(date));
			}
		}
		else {
			setInputValue('');
			setValue(null);
			onChange && onChange(null);
		}
	};

	return (
		<label className={clsx('date-picker__container', error && 'error', disabled && 'disabled')}>
			<span style={{ position: 'relative' }} className="date-picker__container_label">
				{label}
				{important && <span style={{ color: 'red', marginLeft: '.2rem' }}>*</span>}
			</span>

			<Localization
				messages={{ moveToday: t('Сегодня') }}
				date={new DateLocalizer({ culture: locale, firstOfWeek: 1 })}
			>
				<DatePicker
					disabled={disabled}
					value={value}
					name={label}
					onChange={(date) => {
						setValue(date);
						setInputValue(format(date));
						if (onChange) {
							onChange(format(date));
						}
					}}
					selectIcon={
						<svg width="2.4rem" height="2.5rem" fill="none" viewBox="0 0 24 25">
							<path
								fill="#4587E8"
								d="M12 19.908a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm5 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0-4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm-5 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm7-12h-1v-1a1 1 0 0 0-2 0v1H8v-1a1 1 0 0 0-2 0v1H5a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-14a3 3 0 0 0-3-3Zm1 17a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-9h16v9Zm0-11H4v-3a1 1 0 0 1 1-1h1v1a1 1 0 1 0 2 0v-1h8v1a1 1 0 0 0 2 0v-1h1a1 1 0 0 1 1 1v3Zm-13 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 4a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
								opacity=".3"
							/>
						</svg>
					}
				/>
			</Localization>

			<div
				style={{
					position: 'absolute',
					top: '50%',
					transform: 'translateY(-50%)',
					left: 0,
					width: '100%',
					marginTop: '1.5rem',
				}}
				onBlur={dateSendHandler}
				onKeyDown={(e) => e.keyCode === 13 && dateSendHandler()}
			>
				<MaskedTextInput
					disabled={disabled}
					className="masked-input"
					autoComplete="off"
					value={inputValue}
					placeholder={placeholder ?? t('Выберите дату')}
					onChange={(e) => {
						const value = e.target.value;
						setInputValue(value);
					}}
					onBlur={() => dateSendHandler()}
					mask={[/[0-3]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
				/>

				{error && <span className="error-text">{error}</span>}
			</div>
		</label>
	);
}
